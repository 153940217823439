import { resolvePageComponent } from '@adonisjs/inertia/helpers'
import { createInertiaApp } from '@inertiajs/vue3'
import axios from 'axios'
import { DateTime } from 'luxon'
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import DialogService from 'primevue/dialogservice'
import ToastService from 'primevue/toastservice'
import { createApp, h } from 'vue'
import getafile from './getafile_preset'

import 'primeicons/primeicons.css'
import '../css/app.scss'
import '/node_modules/primeflex/primeflex.scss'
import.meta.glob(['../images/favicon/**'])
import VueDiff from 'vue-diff'
import 'vue-diff/dist/index.css'

// interceptor for last request
axios.interceptors.response.use((response) => {
  sessionStorage.setItem('lastRequest', DateTime.local().toISO())
  return response
})

// catch errors during dynamic imports
window.addEventListener('unhandledrejection', (event) => {
  const reason = event.reason
  if (
    reason instanceof Error &&
    (reason.message.includes('Failed to fetch dynamically imported module') ||
      reason.message.includes('Importing a module script failed'))
  ) {
    console.log('Vue component not found: triggering reload')
    const win: Window = window
    const urlParams = new URLSearchParams(win.location.search)
    if (!urlParams.get('release')) {
      const url = new URL(win.location.href)
      url.searchParams.set('release', new Date().toISOString())
      win.location.assign(url.search)
    }
  } else {
    console.log('Unhandled rejection error:', event)
  }
})

const appName = 'Getafile'

createInertiaApp({
  // @ts-ignore
  title: (title: string) => {
    ;`${title} - ${appName}`
  },
  // @ts-ignore
  resolve: (name: string) => {
    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'))
  },
  setup({ el, App, props, plugin }) {
    createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(PrimeVue, {
        // Default theme configuration
        theme: {
          preset: getafile,
          options: {
            prefix: '',
            darkModeSelector: 'system',
            cssLayer: false,
          },
        },
      })
      .use(DialogService)
      .use(ConfirmationService)
      .use(ToastService)
      .use(VueDiff)
      .mount(el)
  },
})
